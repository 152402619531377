<template>
    <div>
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
            class="dashboard"
            tag="div"
        >
            <div class="l-2-00-m--all">
                <div>
                    <h1>
                        {{ $_pagetitle }}
                    </h1>
                </div>
                <div>
                    <Button
                        v-if="$store.getters.getManagements.driver.use"
                        class-name="buttonOvalAccent"
                        text="ドライバーを割り当てる"
                        @action="buttonSelectDriver()"
                    />
                    <Button
                        v-if="readData.orderDatetime && !readData.deletedAt && !readData[management.completed]"
                        class-name="buttonOvalAccent"
                        :text="status.buttonText"
                        @action="buttonStatus()"
                    />
                </div>
            </div>
            <div :class="$style.detailColumn">
                <div :class="$style.detailInner">
                    <div>
                        ▼お客様情報
                    </div>
                    <template v-for="(data, index) in collection">
                        <template v-if="field[data.field].type !== 'file'">
                            <template v-if="index <= 6">
                                <h2 :key="`head${index}`" :class="$style.detailHead">
                                    {{ field[data.field].head }}
                                </h2>
                                <div :key="`div${index}`">
                                    <Input
                                        :data="data"
                                        :disabled="disabled"
                                        :flag-validated-immediate="flagValidatedImmediate"
                                        :input="readData[data.field]"
                                        @input="input[data.field] = $event"
                                    />
                                </div>
                            </template>
                            <template v-if="index === 7">
                                <h2 :key="`head${index}`" :class="$style.detailHead">
                                    {{ field[data.field].head }}
                                </h2>
                                <div :key="`div${index}`">
                                    <Input
                                        :data="data"
                                        :disabled="disabled"
                                        :flag-validated-immediate="flagValidatedImmediate"
                                        :input="readData[data.field]"
                                        @input="input[data.field] = $event"
                                    />
                                    <template v-if="input[data.field] === 'あり'">
                                        <h3 :class="$style.detailSubHead">
                                            {{ field[collection[8].field].head }}
                                        </h3>
                                        <div>
                                            <Input
                                                :data="collection[8]"
                                                :disabled="disabled"
                                                :flag-validated-immediate="flagValidatedImmediate"
                                                :input="readData[collection[8].field]"
                                                @input="input[collection[8].field] = $event"
                                            />
                                        </div>
                                        <h3 :class="$style.detailSubHead">
                                            {{ field[collection[9].field].head }}
                                        </h3>
                                        <div>
                                            <Input
                                                :data="collection[9]"
                                                :disabled="disabled"
                                                :flag-validated-immediate="flagValidatedImmediate"
                                                :input="readData[collection[9].field]"
                                                @input="input[collection[9].field] = $event"
                                            />
                                        </div>
                                        <h3 :class="$style.detailSubHead">
                                            {{ field[collection[10].field].head }}
                                        </h3>
                                        <div>
                                            <Input
                                                :data="collection[10]"
                                                :disabled="disabled"
                                                :flag-validated-immediate="flagValidatedImmediate"
                                                :input="readData[collection[10].field]"
                                                @input="input[collection[10].field] = $event"
                                            />
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <h2 :key="`head${index}`" :class="$style.detailHead">
                                {{ field[data.field].head }}{{ fileIndex[index] + 1 }}
                            </h2>
                            <div :key="`div${index}`">
                                <InputFile
                                    :disabled="disabled"
                                    :key="`file${index}`"
                                    :option="file[fileIndex[index]].option"
                                    @data="file[fileIndex[index]].data = $event"
                                    @delete-flag="file[fileIndex[index]].deleteFlag = $event"
                                    @file-name="$_setFileName(fileIndex[index], $event)"
                                />
                            </div>
                        </template>
                    </template>
                </div>
                <div :class="$style.detailInner">
                    <div>
                        ▼送り先情報
                    </div>
                    <template v-for="(data, index) in collection">
                        <template v-if="11 <= index">
                            <template v-if="field[data.field].type !== 'file'">
                                <h2 :key="`head${index}`" :class="$style.detailHead">
                                    {{ field[data.field].head }}
                                </h2>
                                <div :key="`div${index}`">
                                    <Input
                                        :data="data"
                                        :disabled="disabled"
                                        :flag-validated-immediate="flagValidatedImmediate"
                                        :input="readData[data.field]"
                                        @input="input[data.field] = $event"
                                    />
                                </div>
                            </template>
                            <template v-else>
                                <h2 :key="`head${index}`" :class="$style.detailHead">
                                    {{ field[data.field].head }}{{ fileIndex[index] + 1 }}
                                </h2>
                                <div :key="`div${index}`">
                                    <InputFile
                                        :disabled="disabled"
                                        :key="`file${index}`"
                                        :option="file[fileIndex[index]].option"
                                        @data="file[fileIndex[index]].data = $event"
                                        @delete-flag="file[fileIndex[index]].deleteFlag = $event"
                                        @file-name="$_setFileName(fileIndex[index], $event)"
                                    />
                                </div>
                            </template>
                        </template>
                    </template>
                </div>
            </div>
            <h2 :class="$style.tableHead">
                注文内容
            </h2>
            <table :class="$style.table">
                <tr>
                    <th>
                        {{ field.itemName.head }}
                    </th>
                    <th>
                        {{ field.price.head }}
                    </th>
                    <th>
                        注文数
                    </th>
                    <th>
                        小計（税込）
                    </th>
                </tr>
                <tr v-for="(data, index) in readData.order" :key="`tr${index}`">
                    <td>
                        {{ data.itemName }}
                    </td>
                    <td>
                        {{ data.price }}{{ field.price.unit }}
                    </td>
                    <td>
                        <input :value="data.quantity" type="number" disabled>
                    </td>
                    <td>
                        {{ calcItemSubTotal(data.price, data.quantity) }}円
                    </td>
                </tr>
                <tr>
                    <td>
                        小計
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        {{ readData.subTotal }}円
                    </td>
                </tr>
                <tr>
                    <td>
                        送料
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        {{ readData.shippingFee }}円
                    </td>
                </tr>
                <tr>
                    <td>
                        合計金額
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        {{ readData.total }}円
                    </td>
                </tr>
            </table>
            <div class="l-2-00-m--all" :class="$style.buttonArea">
                <div>
                    <Button
                        type="back"
                        @action="$_buttonBack()"
                    />
                    <Button
                        v-if="editMode !== 'detail'"
                        :disabled="invalid"
                        type="next"
                        @action="$_buttonNext()"
                    />
                </div>
                <div>
                    <Button
                        v-if="$store.getters.getManagements[name].button.delete && (editMode === 'update' || editMode === 'detail')"
                        text="削除する"
                        type="delete"
                        @action="$_buttonDelete()"
                    />
                </div>
            </div>
        </ValidationObserver>
        <Modal
            :modal="modal"
        />
    </div>
</template>

<script>
    "use strict";

    import dashboard from "@/mixins/dashboard.js";
    import detail from "@/mixins/detail.js";

    export default {
        "mixins": [
            dashboard,
            detail,
        ],
        "props": {
            "editMode": {
                "type": String,
                "required": true,
            },
            "history": {
                "type": Boolean,
                "required": false,
                "default": false,
            },
            "id": {
                "type": String,
                "required": false,
                "default": "",
            },
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "status": {
                    "buttonText": "",
                    "state": "",
                },
            };
        },
        created() {
            if (this.editMode === "update" || this.editMode === "detail") {
                this.$_created().then(() => {
                    this.checkedStatus();
                });
            }
            else {
                this.$_created();
            }
        },
        "methods": {
            buttonSelectDriver() {
                this.$_modalOpen({
                    "data": {
                        "message": {
                            "start": "ドライバーを選択してください。",
                            "end": "ドライバーを割当てました！",
                            "error": "ドライバーの割当てに失敗しました。",
                        },
                        "button": {
                            "primary": "割当てる",
                            "cancel": "キャンセル",
                            "finished": `${this.management.title}一覧へ戻る`,
                            "error": "閉じる",
                        },
                    },
                    "form": "selectDriver",
                    "editMode": "create",
                    "callback": {
                        "start": response => {
                            this.input.driverName = response.driverName;
                            const doc = this.id;
                            this.$_update(doc).then(() => {
                                this.$_modalFinished();
                            }).catch(error => {
                                console.error(error.message);
                                this.$_modalError("update");
                            });
                        },
                        "end": () => {
                            this.$router.push(this.$_link("list", this.name));
                        },
                    },
                });
            },
            // 状態を変更
            buttonStatus() {
                const state = this.status.state;
                const timestamp = this.$_firestoreTimestamp();
                if (state === "printed") {
                    this.input.printedDatetime = timestamp;
                }
                else if (state === "deposited") {
                    this.input.depositedDatetime = timestamp;
                }
                else if (state === "sent") {
                    this.input.sentDatetime = timestamp;
                }
                this.$_firestoreUpdate({
                    "collection": this.management.collection,
                    "doc": this.id,
                    "set": this.input,
                }).then(() => {
                    this.$router.push({
                        "name": "orderList",
                    });
                }).catch(error => {
                    console.error(error.message);
                });
            },
            // 状態を判定
            checkedStatus() {
                if (!this.readData.printedDatetime) {
                    this.status.buttonText = "印刷済にする";
                    this.status.state = "printed";
                }
                else if (!this.readData.depositedDatetime && this.readData.paymentMethod === "銀行振込（前払い）") {
                    this.status.buttonText = "入金済にする";
                    this.status.state = "deposited";
                }
                else {
                    this.status.buttonText = "発送済にする";
                    this.status.state = "sent";
                }
            },
            // 項目ごとの小計を計算
            calcItemSubTotal(price, quantity) {
                return price * quantity;
            },
        },
    };
</script>

<style lang="scss" module>
    $width: 480px; 
    $space: 32px;
    $border: 1px;

    .buttonArea {
        margin: 72px 0 0;
    }

    .detailHead {
        margin: 40px 0 8px;
    }

    .detailSubHead {
        margin: 24px 0 4px;
    }

    .detailColumn {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        margin-top: 40px;
        .detailInner {
            &:first-child {
                border-right: $border solid #bbb;
                padding-right: $space;
                width: $width + $space + $border;
            }
            &:last-child {
                padding-left: $space;
                width: $width + $space;
            }
        }
    }

    .tableHead {
        margin-top: 72px;
    }

    table {
        &.table {
            margin-top: 8px;
            max-width: 1025px;
            tr {
                th,
                td {
                    &:not(:first-child) {
                        width: 15%;
                    }
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }
</style>
